import { useState, useEffect } from 'react';
import {
  BrazeFeatureFlagT,
  getSubscribeFF,
} from '@/components/commons/brazeUtils';

const useSubscribeFF = (featureFlagId: string) => {
  const [value, setValue] = useState<BrazeFeatureFlagT>({ enabled: false });

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await getSubscribeFF(featureFlagId);

        setValue(res);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching Feature Flag:', featureFlagId, error);
      }
    };

    getSubscription();
  }, [featureFlagId]);

  return value;
};

export default useSubscribeFF;
