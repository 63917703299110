import {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
  createContext,
} from 'react';
import { Workbox } from 'workbox-window';
import { SWRConfig } from 'swr';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import NProgress from 'nprogress';
import type {
  Cart,
  BrazeFeatureFlag,
} from '@lambda/commons/apis/shopify/types/storefront';
import {
  ReebeloStoreT,
  REEBELO_DOMAINS,
  REEBELO_STORE_TO_LOCALE,
} from '@lambda/reebelo/src/types';
import setupMixpanel, { mixpanel } from '@/lib/mixpanelUtils';
import '@/lib/inject-sentry'; // need to be placed at the top in order to cache error in dependencies
import useRouterTransition from '@/lib/use-router-transition';
import { AuthContext, getSavedToken } from '@/lib/auth-token';
import settings from '@/settings';
import GTM, {
  install,
  GA_UA_ID,
  triggerInitalEvents,
  triggerInitalGAdsEvents,
} from '@/lib/google-tag-manager';

import '../styles/fonts.scss';
import '../styles/globals.scss';
import '../public/nprogress.css';
import '@lambda/ui/dist/tailwind.css';
import useCustomerZipCode, {
  ZipcodeStateData,
} from '@/lib/use-customer-zipcode';

import {
  openBrazeSession,
  changeBrazeUser,
  addBrazeAlias,
  setBrazeEmail,
  setBrazePhoneNumber,
  getAllBrazeFeatureFlags,
  getBrazeContentCards,
} from '@/components/commons/brazeUtils';
// import shopifyInstance, { fetchShopifyCustomerInfo } from '@/lib/shopify';
import useSubscribeFF from '@/lib/useSubscribeFF';
import { fetchShopifyCustomerInfo } from '@/lib/shopify';

const Header = dynamic(() => import('@/components/commons/Header'));
const Footer = dynamic(() => import('@/components/commons/Footer'), {
  ssr: false,
});
const FeaturedSection = dynamic(
  () => import('@/components/commons/Footer/FeaturedSection'),
  { ssr: false },
);

NProgress.configure({ showSpinner: false });
interface NoopProps {
  children?: ReactNode;
}

const Noop: FC<NoopProps> = ({ children }) => <>{children}</>;
const fetcher = (url: string) =>
  fetch(url).then((response) => {
    if (response.ok) return response.json();
    throw Error(response.statusText);
  });

declare global {
  interface Window {
    zE?: (name: string, envent: string, option?: string) => void;
  }
}

type ProviderProps = {
  cartCount: number;
  setCartCount: (value: number) => void;
  cart: Cart | null;
  setCart: (cart: Cart | null) => void;
  isLoadingCart: boolean;
  setIsLoadingCart: (loading: boolean) => void;
  isMixPanelReady: boolean;
  setMixpanelReady: (value: boolean) => void;
  setZipcodeStateData: (data: ZipcodeStateData) => void;
  zipcodeStateData: ZipcodeStateData | null;
  featureFlags: BrazeFeatureFlag[] | null;
  setFeatureFlags: (featureFlags: BrazeFeatureFlag[] | null) => void;
  contentCards: Record<string, any>;
};

export const AppCtx = createContext({} as ProviderProps);

const useAppCtxProvider: () => {
  cartCount: number;
  isMixPanelReady: boolean;
  setCartCount: (value: number) => void;
  cart: Cart | null;
  setCart: (cart: Cart | null) => void;
  isLoadingCart: boolean;
  setIsLoadingCart: (loading: boolean) => void;
  setMixpanelReady: () => void;
  setZipcodeStateData: (data: ZipcodeStateData) => void;
  zipcodeStateData: ZipcodeStateData | null;
  featureFlags: BrazeFeatureFlag[] | null;
  setFeatureFlags: (featureFlags: BrazeFeatureFlag[] | null) => void;
  Provider: React.Provider<ProviderProps>;
  contentCards: Record<string, any>;
} = () => {
  const [cartCount, setCount] = useState(0);
  const [cart, setCart] = useState<Cart | null>(null);
  const [isLoadingCart, setIsLoadingCart] = useState<boolean>(true);
  const [isMixPanelReady, setMixpanelReady] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<BrazeFeatureFlag[] | null>(
    null,
  );
  const {
    data: zipcodeStateData,
    setToLocalStorage: setZipcodeStateData,
    initialFetching,
  } = useCustomerZipCode();
  const [contentCards, setContentCards] = useState<Record<string, any>>({});

  useEffect(() => {
    getBrazeContentCards((event) => {
      if (!event.cards) return;
      const dictionary: Record<string, any> = {};

      event.cards.forEach((card: any) => {
        if (card.extras.site_location)
          dictionary[card.extras.site_location] = card;
      });

      setContentCards(dictionary);
    });
  }, []);

  const setCartCount = (value: number) => {
    setCount(value);
  };

  const setMixpanel = () => {
    setMixpanelReady(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') initialFetching();
  }, []);

  return {
    cartCount,
    setCartCount,
    cart,
    setCart,
    isLoadingCart,
    setIsLoadingCart,
    isMixPanelReady,
    Provider: AppCtx.Provider,
    setMixpanelReady: setMixpanel,
    zipcodeStateData,
    setZipcodeStateData,
    featureFlags,
    setFeatureFlags,
    contentCards,
  };
};

function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop;
  const isTransitioning = useRouterTransition();
  const [isLauncherHidden, setLauncherHidden] = useState(false);
  const [cartId, setCartId] = useState('');

  useEffect(() => {
    if (isTransitioning) NProgress.start();
    else NProgress.done();
  }, [isTransitioning]);

  const provider = useRef(new Map());
  const router = useRouter();
  const {
    cartCount,
    setCartCount,
    cart,
    setCart,
    isLoadingCart,
    setIsLoadingCart,
    isMixPanelReady,
    setMixpanelReady,
    Provider: AppCtxProvider,
    zipcodeStateData,
    setZipcodeStateData,
    featureFlags,
    setFeatureFlags,
    contentCards,
  } = useAppCtxProvider();
  const value = {
    cartCount,
    setCartCount,
    cart,
    setCart,
    isLoadingCart,
    setIsLoadingCart,
    isMixPanelReady,
    setMixpanelReady,
    zipcodeStateData,
    setZipcodeStateData,
    featureFlags,
    setFeatureFlags,
    contentCards,
  };

  useEffect(() => {
    setupMixpanel(setMixpanelReady);
  }, []);

  const tokenWrapper = getSavedToken();
  const token = tokenWrapper?.token;

  useEffect(() => {
    async function fetchAndSetBrazeUserId() {
      if (token) {
        // User is logged in
        const { customerId, customerEmail, customerPhone } =
          await fetchShopifyCustomerInfo(token);
        const userId = customerEmail ?? customerId;

        if (userId) changeBrazeUser(userId);

        if (customerId) {
          addBrazeAlias(customerId, 'shopify_customer_id');
          addBrazeAlias(
            customerId,
            `shopify_customer_id_${process.env.STORE || 'reebelo-us'}`,
          );
        }
        if (customerEmail) setBrazeEmail(customerEmail);
        if (customerPhone) setBrazePhoneNumber(customerPhone);

        return;
      }
      // User is Anonymous
      openBrazeSession();

      const mixpanelDistinctId = isMixPanelReady
        ? mixpanel.get_distinct_id()
        : null;

      if (mixpanelDistinctId)
        addBrazeAlias(mixpanelDistinctId, 'braze_anonymous_user_id');
    }

    fetchAndSetBrazeUserId();
  }, [token, isMixPanelReady]);

  useEffect(() => {
    async function fetchAndSetFeatureFlags() {
      const output = await getAllBrazeFeatureFlags();

      setFeatureFlags(output);
    }
    fetchAndSetFeatureFlags();
  }, [token]);

  useEffect(() => {
    if (!('serviceWorker' in navigator) || process.env.STAGE === 'dev') {
      console.warn('Pwa support is disabled');

      return;
    }
    // Any changes to service worker location must be reflected in next.config.js and brazeUtils.ts
    const wb = new Workbox('/sw.js', { scope: '/' });

    wb.register();
  }, []);

  // hide zendesk widget for mobile product/old/collection page
  useEffect(() => {
    if (!window || !window.zE || !document) return;

    window.zE('webWidget', 'show');

    if (document.body.clientWidth > 1024) return;
    const { pathname } = router;

    switch (pathname) {
      case '/collections/[handle]':
      case '/products/[handle]':
      case '/cart':
        window.zE('webWidget', 'hide');
        break;

      default:
        window.zE('webWidget', 'show');
        break;
    }
  }, [router.pathname, isLauncherHidden]);

  useEffect(() => {
    if (GA_UA_ID) {
      GTM.genericEvent({
        event: 'page_view',
        page_title: document.title,
        page_path: router.pathname,
        page_location: window.location.href,
        send_to: GA_UA_ID,
      });
    }
  }, [router.pathname]);

  useEffect(() => {
    if (cart?.id != null && cart.id.includes('/'))
      setCartId(cart.id.split('/').slice(-1)[0]);
  }, [cart?.id]);

  useEffect(() => {
    const savedToken = getSavedToken();

    if (isMixPanelReady && savedToken !== null) {
      // dynamically import to reduce the bundle size
      import('@/lib/use-auth').then((useAuth) => {
        useAuth.mixpanelIdentifyUser(savedToken.token);
      });
    }

    if (isMixPanelReady) {
      const query = new URLSearchParams(window.location.search);
      const utm_source = query.get('utm_source');
      const utm_medium = query.get('utm_medium');
      const utm_campaign = query.get('utm_campaign');
      const utm_term = query.get('utm_term');
      const mixpanelUtmSource = mixpanel.get_property('UTM Source');
      const returningUtmSource = sessionStorage.getItem('returning_utm_source');

      if (
        returningUtmSource &&
        mixpanelUtmSource &&
        !utm_source &&
        !utm_medium &&
        !utm_campaign &&
        !utm_term
      )
        mixpanel.register({ 'UTM Source': null });
      else if (utm_source)
        sessionStorage.setItem('returning_utm_source', utm_source);
    }
  }, [isMixPanelReady]);

  useEffect(() => {
    // set referrer in session storage only if it's not set
    if (typeof window === 'undefined') return;
    const initialReferrer = sessionStorage.getItem('initialReferrer');

    if (!initialReferrer)
      sessionStorage.setItem('initialReferrer', document.referrer);
  }, []);

  const { enabled } = useSubscribeFF('hreflang-all-pages');

  const showHreflangOnAllPages = !enabled;

  const isChildVariant =
    router.pathname.includes('/collections') && router.asPath.includes('?');

  return (
    <>
      {settings.store === 'reebelo-us' && cartId !== '' && (
        <Script
          strategy="afterInteractive"
          id="sig-api"
          // TODO: this is always empty???
          data-order-session-id={`${cartId}`}
          src="https://cdn-scripts.signifyd.com/api/script-tag.js"
        />
      )}
      {install && (
        // Should not be in <head> https://nextjs.org/docs/messages/no-script-tags-in-head-component
        <Script
          id="gtm-script"
          strategy="afterInteractive"
          onLoad={triggerInitalEvents}
          dangerouslySetInnerHTML={{ __html: install.script }}
        />
      )}
      {install && (
        <Script
          onLoad={triggerInitalGAdsEvents}
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=AW-10974721493"
        />
      )}
      {settings.zendesk_widget_key !== '' && (
        <Script
          strategy="lazyOnload"
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${settings.zendesk_widget_key}`}
          onLoad={() => {
            if (window.zE) {
              window.zE('webWidget', 'hide');
              setLauncherHidden(true);
            }
          }}
        />
      )}
      <Script
        strategy="afterInteractive"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${settings.klaviyo.public_api_key}`}
      />
      <Head>
        {(settings.store === 'reebelo-dev' || process.env.STAGE !== 'prod') && (
          <meta name="robots" content="noindex" />
        )}
        <meta data-store={settings.store} data-stage={process.env.NODE_ENV} />
        {settings.store === 'quista' && (
          <meta
            name="facebook-domain-verification"
            content="nlz65srvmosqjfb7ix1me1i3xaxnt5"
          />
        )}
        <link
          rel="icon"
          type="image/png"
          href="/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="preload"
          href="/fonts/ModernEra-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href="/fonts/ModernEra-Black.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href="/fonts/AcuminProWide-UltraBlack.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link rel="preconnect" href="https://cdn.shopify.com"></link>
        {/* to exclude related page (may unavailable on other store)and URL contained query string */}
        {((!showHreflangOnAllPages &&
          ![
            '/collections',
            '/products',
            '/blogs',
            '/about-us',
            '/help',
            '/reviews',
            '/sell-phone',
            '/track-your-order',
            '/buyback',
            '/buyback-form',
          ].some((s) => router.pathname.includes(s))) ||
          (showHreflangOnAllPages && !isChildVariant)) &&
          [
            ['reebelo-us', 'x-default'],
            ...Object.entries(REEBELO_STORE_TO_LOCALE).filter(
              ([key]) =>
                ![
                  'reebelo-dev',
                  'reebelo-tw',
                  'reebelo-kr',
                  'reebelo-b2b-dev',
                  'reebelo-b2b',
                ].includes(key),
            ),
          ].map(([store, locale]) => (
            <link
              key={`${store}-${locale}`}
              rel="alternate"
              hrefLang={locale}
              href={`https://${REEBELO_DOMAINS[store as ReebeloStoreT]}${
                router.asPath.split('?')[0]
              }`}
            />
          ))}
        {/* for setting the cannonical path */}
        {
          <link
            rel="canonical"
            href={`https://${REEBELO_DOMAINS[settings.store]}${
              router.asPath.split('?')[0]
            }`}
          />
        }
      </Head>
      <SWRConfig value={{ fetcher, provider: () => provider.current }}>
        <AuthContext.Provider value={getSavedToken()}>
          <AppCtxProvider value={value}>
            <Header />
            <Layout pageProps={pageProps}>
              <Component {...pageProps} />
            </Layout>
            <FeaturedSection />
            <Footer />
          </AppCtxProvider>
        </AuthContext.Provider>
      </SWRConfig>
    </>
  );
}

export default MyApp;
