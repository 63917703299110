import { TypesTimeZones } from '@zerodep/types.timezones';

export type ReebeloB2bStoreT = 'reebelo-b2b-dev' | 'reebelo-b2b';
export type ReebeloStoreT =
  | 'reebelo-dev'
  | 'reebelo-au'
  | 'reebelo-kr'
  | 'reebelo-nz'
  | 'quista'
  | 'reebelo-tw'
  | 'reebelo-us'
  | 'reebelo-ca';

export enum ReebeloB2bConditions {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}
// Good & Acceptable overlap
export enum ReebeloConditions {
  BrandNew = 'Brand New',
  Premium = 'Premium',
  AsNew = 'As New',
  Pristine = 'Pristine',
  Excellent = 'Excellent',
  VeryGood = 'Very Good',
  Good = 'Good',
  Acceptable = 'Acceptable',
}
// We keep this for subsequent stores
export const REEBELO_CONDITIONS = (store: ReebeloStoreT) =>
  ['reebelo-dev'].includes(store)
    ? [
        ReebeloConditions.BrandNew,
        ReebeloConditions.Premium,
        ReebeloConditions.Pristine,
        ReebeloConditions.Excellent,
        ReebeloConditions.Acceptable,
      ]
    : [
        ReebeloConditions.BrandNew,
        ReebeloConditions.Premium,
        ReebeloConditions.Pristine,
        ReebeloConditions.Excellent,
        ReebeloConditions.Good,
        ReebeloConditions.Acceptable,
      ];
export enum ReebeloConditionsKR {
  AsNew = 'S급',
  Excellent = 'A급',
  VeryGood = 'B급',
  Good = 'C급',
}
export const REEBELO_B2B_STORES: ReebeloB2bStoreT[] =
  process.env.STAGE === 'prod' || process.env.STAGE === 'test'
    ? ['reebelo-b2b']
    : ['reebelo-b2b-dev'];

export const REEBELO_STORES: ReebeloStoreT[] =
  process.env.STAGE === 'prod' || process.env.STAGE === 'test'
    ? [
        'quista',
        'reebelo-au',
        'reebelo-nz',
        'reebelo-kr',
        'reebelo-tw',
        'reebelo-us',
        'reebelo-ca',
      ]
    : ['reebelo-dev'];

export const REEBELO_BLACKLISTED_STORES = ['reebelo-kr', 'reebelo-tw'];

export type ReebeloCurrencies =
  | 'AUD'
  | 'HKD'
  | 'MYR'
  | 'SGD'
  | 'NZD'
  | 'TWD'
  | 'KRW'
  | 'USD'
  | 'CAD';
export const REEBELO_CURRENCY: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  ReebeloCurrencies
> = {
  'reebelo-au': 'AUD',
  'reebelo-nz': 'NZD',
  quista: 'SGD',
  'reebelo-tw': 'TWD',
  'reebelo-dev': 'SGD',
  'reebelo-kr': 'KRW',
  'reebelo-us': 'USD',
  'reebelo-ca': 'CAD',
  'reebelo-b2b': 'USD',
  'reebelo-b2b-dev': 'USD',
};

export const REEBELO_COUNTRY_CODE: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  string
> = {
  'reebelo-au': 'AU',
  'reebelo-nz': 'NZ',
  quista: 'SG',
  'reebelo-dev': 'DEV',
  'reebelo-tw': 'TW',
  'reebelo-kr': 'KR',
  'reebelo-us': 'US',
  'reebelo-ca': 'CA',
  'reebelo-b2b': 'B2B',
  'reebelo-b2b-dev': 'B2B-DEV',
};
export const REEBELO_COUNTRY: Record<ReebeloStoreT | ReebeloB2bStoreT, string> =
  {
    'reebelo-au': 'Australia',
    'reebelo-nz': 'New Zealand',
    quista: 'Singapore',
    'reebelo-dev': 'Singapore',
    'reebelo-tw': 'Taiwan',
    'reebelo-kr': 'Korea',
    'reebelo-us': 'United States of America',
    'reebelo-ca': 'Canada',
    'reebelo-b2b': 'Reebelo B2B',
    'reebelo-b2b-dev': 'Reebelo B2B',
  };
export const REEBELO_DOMAINS: Record<ReebeloStoreT | ReebeloB2bStoreT, string> =
  {
    'reebelo-au': 'reebelo.com.au',
    quista: 'reebelo.sg',
    'reebelo-nz': 'reebelo.co.nz',
    'reebelo-tw': 'reebelo.com.tw',
    'reebelo-kr': 'reebelo.co.kr',
    'reebelo-dev': 'reebelo.blue',
    'reebelo-us': 'reebelo.com',
    'reebelo-ca': 'reebelo.ca',
    'reebelo-b2b': 'reebelo-wholesale.com',
    'reebelo-b2b-dev': 'b2b.reebelo.blue',
  };

export const REEBELO_TZ_NAME: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  TypesTimeZones
> = {
  'reebelo-au': 'Australia/Melbourne',
  quista: 'Asia/Singapore',
  'reebelo-nz': 'Pacific/Auckland',
  'reebelo-tw': 'Asia/Singapore',
  'reebelo-dev': 'Asia/Singapore',
  'reebelo-kr': 'Asia/Seoul',
  'reebelo-us': 'America/Yellowknife', // 'America/Los_Angeles' revert back on 12 Mar 2023
  'reebelo-ca': 'America/Toronto',
  'reebelo-b2b': 'America/Yellowknife', // 'America/Los_Angeles' revert back on 12 Mar 2023
  'reebelo-b2b-dev': 'America/Yellowknife', // 'America/Los_Angeles' revert back on 12 Mar 2023
};

// Can be extended to be 'kr' | 'zh' ...etc
// https://www.loc.gov/standards/iso639-2/php/code_list.php
export type ReebeloLanguage = 'en' | 'ko' | 'zh';
export const REEBELO_STORE_TO_LANGUAGE: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  ReebeloLanguage
> = {
  'reebelo-au': 'en',
  quista: 'en',
  'reebelo-nz': 'en',
  'reebelo-tw': 'zh',
  'reebelo-dev': 'en',
  'reebelo-kr': 'ko',
  'reebelo-us': 'en',
  'reebelo-ca': 'en',
  'reebelo-b2b': 'en',
  'reebelo-b2b-dev': 'en',
};
export const REEBELO_LANGUAGE_LONG: Record<ReebeloLanguage, string> = {
  en: 'English',
  ko: 'Korean',
  zh: 'Chinese',
};

export const REEBELO_STORE_TO_LOCALE: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  string
> = {
  'reebelo-us': 'en-US',
  'reebelo-au': 'en-AU',
  'reebelo-nz': 'en-NZ',
  quista: 'en-SG',
  'reebelo-tw': 'zh-TW',
  'reebelo-dev': 'en-SG',
  'reebelo-kr': 'ko',
  'reebelo-ca': 'en-CA',
  'reebelo-b2b': 'en-US',
  'reebelo-b2b-dev': 'en-US',
};
export const REEBELO_STORE_LOCATION_ID: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  number
> = {
  'reebelo-dev': 52150796454,
  quista: 31460884535,
  'reebelo-au': 49451335829,
  'reebelo-nz': 60921118876,
  'reebelo-kr': 63234015325,
  'reebelo-tw': 64223445150,
  'reebelo-us': 66716532919,
  'reebelo-ca': 86033301806,
  'reebelo-b2b': 84536656169,
  'reebelo-b2b-dev': 88012751143,
};

export const ORDER_PREFIX: Record<ReebeloStoreT | ReebeloB2bStoreT, string> = {
  quista: '#',
  'reebelo-au': '#A',
  'reebelo-tw': '#TW',
  'reebelo-nz': '#NZ',
  'reebelo-kr': '#KR',
  'reebelo-us': '#US',
  'reebelo-ca': '#CA',
  'reebelo-dev': '#',
  'reebelo-b2b': '#B2B',
  'reebelo-b2b-dev': '#B2B',
};

export const REEBELO_STORE_TO_SALES_CHANNELS: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  string[]
> = {
  'reebelo-au': ['Online Store', 'Storefront v2'],
  'reebelo-nz': ['Online Store', 'Storefront v2'],
  quista: ['Online Store', 'Storefront V2'], // intentionally upper case the v2 to match storefront app name on Shopify
  'reebelo-tw': ['Online Store'],
  'reebelo-dev': ['Online Store', 'Storefront v2'],
  'reebelo-kr': ['Online Store', 'Storefront v2'],
  'reebelo-us': ['Online Store', 'Storefront v2'],
  'reebelo-ca': ['Online Store', 'Storefront V2'],
  'reebelo-b2b': ['Online Store', 'Storefront V2'],
  'reebelo-b2b-dev': ['Online Store', 'Storefront V2'],
};

export const REEBELO_US_SERVICE_PROVIDERS: string[] = [
  'Unlocked',
  'AT&T',
  'Verizon',
  'T-Mobile',
  'Others',
];

export const REEBELO_US_MVNO: { mvno: string; mvnoProvider: string }[] = [
  {
    mvno: 'Access Wireless',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Affinity Cellular',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Airvoice Wireless',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Allvoi Wireless',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Armed Forces Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Assist Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Assurance Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Best Cellular',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Black Wireless',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'boom! MOBILE',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Boost Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Cell Nuvo',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Consumer Cellular',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'CREDO Mobile',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Cricket Wireless',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'easyGO Wireless',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'EcoMobile',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Extreme Connect ',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'enTouch Wireless',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Feelsafe Wireless',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Flash Wireless',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'FreedomPop',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'FreeUP Mobile',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Gen Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Good2Go Mobile',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Google Fi',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'GoSmart Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'h2o Wireless',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Hello Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Jethro Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Jolt Mobile',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Kroger Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Kynect',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Liberty Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Lycamobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Madstar Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Metro by T-Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Mint Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Net10 Wireless',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Optimum Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Page Plus Cellular',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Patriot Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Pix Wireless',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Pulse Cellular',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Puppy Wireless',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Pure TalkUSA',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Q Link Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Reach Mobile',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Red Pocket Mobile',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Republic Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Seawolf Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Selectel Wireless',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Simple Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Spectrum Mobile',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'SpeedTalk Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'StandUp Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Straight Talk',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'TAG Mobile',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Telcel América',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Tello Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'TerraCom Wireless',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'TextNow',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Ting',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Total Wireless',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Tracfone',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Twigby',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Ultra Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Unreal Mobile',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'US Mobile',
    mvnoProvider: 'Unlocked',
  },
  {
    mvno: 'Visible',
    mvnoProvider: 'Verizon',
  },
  {
    mvno: 'Walmart Family Mobile',
    mvnoProvider: 'T-Mobile',
  },
  {
    mvno: 'Wing',
    mvnoProvider: 'AT&T',
  },
  {
    mvno: 'Xfinity Mobile',
    mvnoProvider: 'Verizon',
  },
];

export enum ReebeloGtinRegion {
  APAC = 'Apac',
  USA = 'Usa',
  CA = 'Canada',
}
export const REEBELO_STORES_TO_REGIONS: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  ReebeloGtinRegion
> = {
  'reebelo-dev': ReebeloGtinRegion.USA,
  'reebelo-au': ReebeloGtinRegion.APAC,
  'reebelo-nz': ReebeloGtinRegion.APAC,
  quista: ReebeloGtinRegion.APAC,
  'reebelo-kr': ReebeloGtinRegion.APAC,
  'reebelo-tw': ReebeloGtinRegion.APAC,
  'reebelo-us': ReebeloGtinRegion.USA,
  'reebelo-ca': ReebeloGtinRegion.CA,
  'reebelo-b2b': ReebeloGtinRegion.USA,
  'reebelo-b2b-dev': ReebeloGtinRegion.USA,
};
export enum ReebeloVendorsInvetoryOrigins {
  HONG_KONG = 'Hong Kong',
  NEW_ZEALAND = 'New Zealand',
  AUSTRALIA = 'Australia',
  USA = 'United States',
}

// Only used for returns atm
export const REEBELO_COUNTRY_CODE_TO_STATES: Record<string, Array<string>> = {
  AU: ['NSW', 'VIC', 'ACT', 'QLD', 'SA', 'WA', 'TAS', 'NT'],
  NZ: [
    'Auckland',
    'Bay of Plenty',
    'Canterbury',
    'Christchurch',
    'Chatham Islands Territory',
    'Gisborne',
    'Greater Wellington',
    "Hawke's Bay",
    'Manawatū-Whanganui',
    'Marlborough',
    'Nelson',
    'Northland',
    'Ngahinapouri',
    'Otago',
    'Paihia',
    'Southland',
    'Taranaki',
    'Tauranga',
    'Tasman',
    'Waikato',
    'West Coast',
  ],
  SG: ['SG'],
  DEV: ['SG'],
  US: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  CA: [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
  ],
};
