import ShopifyStoreFrontResponse from '@lambda/commons/apis/shopify/types/storefront';
import ShopifyBase from '@lambda/commons/apis/shopify/base';
import settings from '@/settings';
import shopifyApi from '@/lib/shopify';

type ShopifyCustomer = {
  customerId?: string;
  customerEmail?: string;
  customerPhone?: string;
};

export default new ShopifyBase<ShopifyStoreFrontResponse>({
  store: settings.store,
  password: settings.shopifyStoreFrontApiKey,
  isStoreFront: true,
  shouldThrottle: false,
  version: '2022-10',
});

export async function fetchShopifyCustomerInfo(
  token: string,
): Promise<ShopifyCustomer> {
  const auth = await shopifyApi.fetch({
    query: `query($token: String!){
                    customer(customerAccessToken:$token) {
                      id,
                      email,
                      phone
                    }
                  }`,
    variables: { token },
  });

  const idMatch = auth?.customer?.id?.match(/(\d+)/);

  return {
    customerId: idMatch?.[0] ?? auth?.customer?.id,
    customerEmail: auth?.customer?.email,
    customerPhone: auth?.customer?.phone,
  };
}
