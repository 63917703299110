import { ILogger } from './ILogger';
import { ClientLogger } from './clientLogger';

export class Logger implements ILogger {
  private logger: ILogger;

  constructor(traceId: string) {
    this.logger = new ClientLogger(traceId);

    if (typeof window === 'undefined') {
      import('./serverLogger').then((type) => {
        this.logger = new type.ServerLogger(traceId);
      });
    }
  }

  extend(childTraceId: string) {
    return this.logger.extend(childTraceId);
  }

  info(formatter: any, ...args: any[]) {
    return this.logger.info(formatter, ...args);
  }

  error(formatter: any, ...args: any[]) {
    return this.logger.error(formatter, ...args);
  }

  warn(formatter: any, ...args: any[]) {
    return this.logger.warn(formatter, ...args);
  }

  debug(formatter: any, ...args: any[]) {
    return this.logger.debug(formatter, ...args);
  }
}
