import PQueue from 'p-queue';
import delay from 'delay';
import { REEBELO_B2B_STORES, ReebeloB2bStoreT } from '@lambda/reebelo';
import axios, { AxiosInstance } from '../axios';
import logAxios from '../../logAxios';
import { ShopifyGraphQlResponse } from './types/graphql';
import { STAGE } from '../environments';
import ShopifyStoreFrontResponse from './types/storefront';
import { Logger } from '../../logging/logger';
import ShopifyRestQueryResponse from './types/rest';
import { RetryOptions, retry } from '../../resilience/retry';

export const SHOPIFY_HEADER_AUTH_GRAPHQL = 'X-Shopify-Access-Token';
const SHOPIFY_HEADER_AUTH_STOREFRONT = 'X-Shopify-Storefront-Access-Token';
const B2B_DEFAULT_VERSION = '2023-07';
const DEFAULT_VERSION = '2022-04';
// We cache Shopify limit instances so if a Shopify class is intanciated twice, they share the limit.
const limitCache: Record<string, PQueue> = {};

const getLimit = (store: string, maxConcurrent: number) => {
  if (!(store in limitCache)) {
    limitCache[store] = new PQueue({
      interval: 1000,
      intervalCap: maxConcurrent,
    });
  }

  return limitCache[store];
};
const DEFAULT_MAX_CONCURRENT_REQUESTS = 40;

export default class ShopifyBase<
  FetchResponse extends
    | ShopifyGraphQlResponse
    | ShopifyStoreFrontResponse = ShopifyGraphQlResponse,
> {
  protected api: AxiosInstance;

  protected logger: Logger;

  private shouldThrottle: boolean;

  private throttling: Promise<void> = Promise.resolve();

  private limit: PQueue;

  protected isStoreFront: boolean;

  constructor(creds: {
    store: string;
    password: string;
    version?: `${2021 | 2022}-${'01' | '04' | '07' | '10'}`; // '2022-01', '2021-04';
    isStoreFront?: true;
    maxConcurrent?: number;
    shouldThrottle?: false;
    useMultiKeys?: false;
  }) {
    this.isStoreFront = creds.isStoreFront === true;
    const maxConcurrent =
      creds.maxConcurrent || DEFAULT_MAX_CONCURRENT_REQUESTS;
    const logger = new Logger(`shopify:${creds.store}`);
    const isB2BStore = REEBELO_B2B_STORES.includes(
      creds.store as ReebeloB2bStoreT,
    );
    const version = isB2BStore
      ? B2B_DEFAULT_VERSION
      : creds.version || DEFAULT_VERSION;
    const headerName = this.isStoreFront
      ? SHOPIFY_HEADER_AUTH_STOREFRONT
      : SHOPIFY_HEADER_AUTH_GRAPHQL;
    const baseURL = this.isStoreFront
      ? `https://${creds.store}.myshopify.com/api/${version}/`
      : `https://${
          creds.store === 'reebelo-ca' ? 'reebelo-canada' : creds.store
        }.myshopify.com/admin/api/${version}/`;
    const api = axios.create({
      baseURL,
      headers: {
        [headerName]: creds.password,
        'Content-Type': 'application/json',
      },
    });

    this.logger = logger;
    this.api = api;
    this.limit = getLimit(creds.store, maxConcurrent);
    this.shouldThrottle = creds.shouldThrottle == null;
    logAxios(api, logger, {
      request: (req) => ({
        ...req,
        params: req?.data?.variables,
        data: req?.data?.query
          ?.replace(/\n/g, '')
          ?.replace(/( |\\t)+/g, ' ')
          ?.trim(),
      }),
      response: (res) => {
        const cost = res.data?.extensions?.cost?.actualQueryCost;
        const { currentlyAvailable, maximumAvailable } =
          res.data?.extensions?.cost?.throttleStatus || {};

        return {
          ...res,
          log: ` - Cost:${cost} -> ${currentlyAvailable}/${maximumAvailable}`,
        };
      },
    });
  }

  async fetchRest(params: {
    url: string;
    body?: Record<string, any>;
    method?: string;
  }) {
    const result = await this.limit.add(() => {
      if (params.method === 'GET')
        return this.api.get<ShopifyRestQueryResponse>(`${params.url}.json`);

      if (params.method === 'PUT') {
        return this.api.put<ShopifyRestQueryResponse>(
          `${params.url}.json`,
          params.body,
        );
      }

      if (params.method === 'DEL') {
        return this.api.delete<ShopifyRestQueryResponse>(
          `${params.url}.json`,
          params.body,
        );
      }

      return this.api.post<ShopifyRestQueryResponse>(
        `${params.url}.json`,
        params.body,
      );
    });

    if ((result as any).errors?.length > 0) {
      this.logger.error('Error from %j', params);
      throw Error((result as any).errors.map((e: any) => e.message).join('\n'));
    }

    return result.data;
  }

  async fetch(params: {
    query: string;
    variables: Record<string, any>;
  }): Promise<FetchResponse['data']> {
    if (!this.isStoreFront) await this.throttling;
    const result = await this.limit.add(() =>
      this.api
        .post<FetchResponse>('/graphql.json', params)
        .then((res) => res.data),
    );

    if (STAGE === 'test') return result.data;
    // check for mutation errors. Shopify returns 200 when mutation has wrong data.
    const mutationError = Object.values(result.data || {}).find(
      (queryData: any) => queryData?.userErrors?.length > 0,
    );

    if (mutationError != null) {
      this.logger.error('MUTATION ERROR: %j', mutationError.userErrors);
      throw Error(
        `Mutation error: ${JSON.stringify(mutationError.userErrors)}`,
      );
    }

    if ((result as any).errors?.length > 0) {
      this.logger.error('Error from %j', params);
      throw Error((result as any).errors.map((e: any) => e.message).join('\n'));
    }
    if (this.isStoreFront) return result.data;
    const { extensions } = result as ShopifyGraphQlResponse;
    // Compute throttling
    const max = extensions.cost.throttleStatus.maximumAvailable;
    const current = extensions.cost.throttleStatus.currentlyAvailable;
    const rate = extensions.cost.throttleStatus.restoreRate;

    if (current / max < 0.2 && this.shouldThrottle) {
      /**
       * We avoid shopify to throttle. If the current cost is less than 20% of total rate, we wait for the rate to be 80% full
       */
      const secToWaitUntilFull = (0.8 * (max - current)) / rate;

      this.limit.pause();
      this.logger.info('Throttling request for %ds', secToWaitUntilFull);
      this.throttling = delay(secToWaitUntilFull * 1000); // we delay throttling to the next query
      this.limit.start();
    }

    return result.data;
  }

  async fetchSafe(params: { query: string; variables: Record<string, any> }) {
    return this.fetch(params).catch((e: any) => {
      this.logger.warn('FAIL SAFE: %s', e.message);

      return null;
    });
  }

  async fetchWithRetry(params: {
    query: string;
    variables: Record<string, any>;
  }) {
    const retries = 2;
    const options: RetryOptions = {
      retries, // maximum 5 times
      minTimeout: 5 * 1000, // min delay 5 secs
      maxTimeout: 12 * 1000, // max delay 12 secs
      onRetry: (error: Error, attempt: number) => {
        this.logger.info(`Retrying ${attempt} times: `, error);
      },
    };

    return retry(async () => this.fetch(params), options).catch(
      (error: any) => {
        this.logger.error(
          `Stop retrying after failed ${retries} times: `,
          error,
        );

        return null;
      },
    );
  }
}
