import { ReebeloConditions } from './types';

export const TAGS = {
  PAUSED: 'product_is_paused',
  AUTOMATED: 'product_is_automated',
  SUBSIDY_PREFIX: 'R_SUBSIDY',
  BEST: 'product_is_best', // lowest score amoung RSKUs
  BEST_PSKU: 'product_is_best_psku',
  DEAL: 'product_is_deal',
};

export function getRsku(tags: string[]): string | undefined {
  return tags
    ?.map((tag) => /^RSKU:(.*)/.exec(tag)?.[1])
    .find((tag) => tag != null && tag !== 'undefined');
}

export function getRskuRaw(tags: string[]): string {
  return tags.find((tag) => tag.startsWith('RSKU:')) || 'RSKU:undefined';
}

export const getPskuRaw = (tags: string[]) =>
  tags.find((tag) => tag.startsWith('PSKU:')) || 'PSKU:undefined';

export function getPsku(tags: string[]): string | undefined {
  return tags
    ?.map((tag) => /^PSKU:(.*)/.exec(tag)?.[1])
    .find((tag) => tag != null && tag !== 'undefined');
}

export const isPaused = (tags: string[]) =>
  tags?.some((tag) => tag === TAGS.PAUSED);

export const isBestOffer = (tags: string[]) =>
  tags?.some((tag) => tag === TAGS.BEST);

export const isOfferDeal = (tags: string[]) =>
  tags?.some((tag) => tag === TAGS.DEAL);
/**
 * WARNING, dynamodb OFFER depends on this object.
 * If you change the VARIANT_ORDER, you will need to touch all products for them to be ingested again in DynamoDb OFFER.
 */
export const VARIANT_BYPASS: Array<keyof Variant> = ['batteryHealth'];

export const VARIANT_ORDER: Array<keyof Variant> = [
  'psku',
  'storage',
  'color',
  'connectivity',
  'ram',
  'simSlot',
  'screenSize',
  'cpu',
  'cellularStatus',
  'cellularNetwork',
  'networkProvider',
  'batteryHealth',
  'condition',
];
export const getBuyboxVariants = (variants: Variant) =>
  VARIANT_ORDER.map((variant) =>
    VARIANT_BYPASS.includes(variant) ? '' : variants[variant],
  );
export type Variant = Partial<{
  psku: string;
  storage: string;
  color: string;
  connectivity: string;
  ram: string;
  simSlot: string;
  screenSize: string;
  watchFaceSize: string;
  watchCaseColor: string;
  watchCaseMaterial: string;
  watchBandType: string;
  watchBandColor: string;
  watchBandSize: string;
  watchBandMaterial: string;
  cpu: string;
  cellularStatus: string;
  cellularNetwork: string;
  networkProvider: string;
  batteryHealth: string;
  condition: ReebeloConditions;
}>;

const tagExtractor = (tags: string[]) => (regex: RegExp) =>
  tags
    ?.map((tag) => regex.exec(tag)?.[1])
    .filter((tag) => !['', 'undefined'].includes(tag || ''))
    .find((tag) => tag);

const extractV1Subsidy = (tags?: Array<string>) => {
  const regex = /R_SUBSIDY_(F|P)_(\d+)/;

  const matchResult = tags
    ?.map((tag) => regex.exec(tag))
    .find((regexResult) => regexResult != null);

  if (matchResult != null) {
    return [
      {
        provider: 'reebelo',
        type: matchResult[1] === 'F' ? 'fixed' : 'percentage',
        value: Number(matchResult[2]),
      },
    ];
  }

  return [];
};

export const extractV3Subsidy = (tags?: Array<string>) => {
  const regex = /SUBSIDY_V_(\d*\.?\d*)#R_(\d*\.?\d*)/;

  const matchResult = tags
    ?.map((tag) => regex.exec(tag))
    .find((regexResult) => regexResult != null);

  if (matchResult != null) {
    return [
      {
        provider: 'vendor',
        type: 'percentage',
        value: Number(matchResult[1]),
      },
      {
        provider: 'reebelo',
        type: 'percentage',
        value: Number(matchResult[2]),
      },
    ];
  }

  return [];
};

// find v3 subsidy first, if not found fallback to find v1 subsidy
const extractSubsidy = (tags?: Array<string>) => {
  const v3Subsidy = extractV3Subsidy(tags);

  if (v3Subsidy.length !== 0) return v3Subsidy;

  return [...extractV1Subsidy(tags)];
};

export const extractVariant = (tags: string[]): Variant => {
  const extract = tagExtractor(tags);

  return {
    psku: getPsku(tags),
    color: extract(/Color_(.*)/),
    condition: extract(/Condition_(.*)/) as ReebeloConditions,
    storage: extract(/Storage_(.*)/),
    connectivity: extract(/Connectivity_(.*)/),
    simSlot: extract(/SimSlot_(.*)/),
    screenSize: extract(/ScreenSize_(.*)/),
    ram: extract(/Ram_(.*)/),
    cpu: extract(/Cpu_(.*)/),
    cellularStatus: extract(/CellularStatus_(.*)/),
    cellularNetwork: extract(/CellularNetwork_(.*)/),
    networkProvider: extract(/NetworkProvider_(.*)/),
    batteryHealth: extract(/BatteryHealth_(.*)/),
    watchBandColor: extract(/WatchBandColor_(.*)/),
    watchCaseColor: extract(/WatchCaseColor_(.*)/),
    watchBandType: extract(/WatchBandType_(.*)/),
    watchBandMaterial: extract(/WatchBandMaterial_(.*)/),
    watchCaseMaterial: extract(/WatchCaseMaterial_(.*)/),
    watchFaceSize: extract(/WatchFaceSize_(.*)/),
  };
};
export const getTagCollections = (tags: string[]) =>
  tags
    .map((tag) => /Category_(.*)/.exec(tag)?.[1])
    .filter((tag): tag is string => tag != null);

export const calculatePriceAfterSubsidy = (
  price: number,
  subsidies: ReturnType<typeof extractSubsidy>,
) => {
  const totalSubsidyAmount = subsidies.reduce(
    (acc, subsidy) => acc + subsidy.value,
    0,
  );
  // we assume the type will be the same for all the subsidies
  const type = subsidies[0]?.type ?? 'fixed';

  return type === 'fixed'
    ? price - totalSubsidyAmount
    : Math.floor(price - Math.floor((price * totalSubsidyAmount) / 100));
};

export const calculatePriceBeforeSubsidy = (
  price: number,
  subsidies: ReturnType<typeof extractSubsidy>,
) => {
  const totalSubsidyAmount = subsidies.reduce(
    (acc, subsidy) => acc + subsidy.value,
    0,
  );
  // we assume the type will be the same for all the subsidies
  const type = subsidies[0]?.type ?? 'fixed';

  return type === 'fixed'
    ? price + totalSubsidyAmount
    : (100 * price) / (100 - totalSubsidyAmount);
};

// find v3 subsidy tag first, if not found fallback to v1 subsidy tag
export const findSubsidyTag = (tags: string[]) => {
  const subsidyV3Tag = tags.find((tag) => tag.startsWith('SUBSIDY_V_'));

  if (subsidyV3Tag != null) return subsidyV3Tag;

  return tags.find((tag) => tag.startsWith('R_SUBSIDY_'));
};

export const extractMinPrice = (tags: string[]) => {
  const extract = tagExtractor(tags);
  const minPrice = extract(/MinPrice_(.*)/);

  return minPrice ? Number(minPrice) : undefined;
};

export const extractTargetPrice = (tags: string[]) => {
  const extract = tagExtractor(tags);
  const targetPrice = extract(/TargetPrice_(.*)/);

  return targetPrice ? Number(targetPrice) : undefined;
};

export const extractTags = (tags: string[]) => {
  const extract = tagExtractor(tags);

  return {
    ...extractVariant(tags),
    watchFaceSize: extract(/WatchFaceSize_(.*)/),
    watchCaseColor: extract(/WatchCaseColor_(.*)/),
    watchCaseMaterial: extract(/WatchCaseMaterial_(.*)/),
    watchBandType: extract(/WatchBandType_(.*)/),
    watchBandColor: extract(/WatchBandColor_(.*)/),
    watchBandSize: extract(/WatchBandSize_(.*)/),
    watchBandMaterial: extract(/WatchBandMaterial_(.*)/),
    category: extract(/Category_(.*)/),
    express: extract(/express_(.*)/),
    subsidy: extractSubsidy(tags),
    brand: extract(/Brand_(.*)/),
    rsku: getRsku(tags),
    isBest: isBestOffer(tags),
    isPaused: isPaused(tags),
    msrp: extract(/MSRP_(.*)/),
    minPrice: extractMinPrice(tags),
    targetPrice: extractTargetPrice(tags),
  };
};

export const toTags = (variant: Partial<Variant>): string[] => {
  const tags = [
    `PSKU:${variant.psku}`,
    `Color_${variant.color}`,
    `Condition_${variant.condition}`,
    `Storage_${variant.storage}`,
    `Connectivity_${variant.connectivity}`,
    `SimSlot_${variant.simSlot}`,
    `ScreenSize_${variant.screenSize}`,
    `Ram_${variant.ram}`,
    `Cpu_${variant.cpu}`,
    `CellularStatus_${variant.cellularStatus}`,
    `CellularNetwork_${variant.cellularNetwork}`,
    `NetworkProvider_${variant.networkProvider}`,
    `BatteryHealth_${variant.batteryHealth}`,
  ];

  if (process.env.NODE_ENV === 'test' && tags.length !== VARIANT_ORDER.length) {
    throw Error(
      `toTags need to return as many tags as there are keys in variants.\n` +
        `Tags length : ${tags.length}\n` +
        `Variants: ${VARIANT_ORDER.length}`,
    );
  }

  return tags.filter((t) => !t.endsWith('undefined')).sort();
};

/**
 * Extract price value from Reebelo price tag
 * @param tags Tags from a Shopify product
 * @returns The actual price value, otherwise -1
 */
export const extractReebeloPrice = (tags: string[]) => {
  if (tags && tags.length > 0) {
    const tag = tags.find((item) =>
      item.replace(/\s/g, '').toLowerCase().startsWith('reebeloprice:'),
    );

    if (tag) {
      const arr = tag.split(':');

      if (arr.length > 1) {
        const price = parseFloat(arr[1]);

        if (!Number.isNaN(price)) return price;
      }
    }
  }

  return undefined;
};

export const encodeTags = (
  params: Partial<{
    category: string;
    color: string;
    condition: string;
    storage: string;
    connectivity: string;
    simSlot: string;
    psku: string;
    ram: string;
    screenSize: string;
    cpu: string;
    cellularStatus: string;
    cellularNetwork: string;
    networkProvider: string;
    batteryHealth: string;
  }>,
) =>
  [
    params.category && `Category_${params.category}`,
    params.color && `Color_${params.color}`,
    params.condition && `Condition_${params.condition}`,
    params.storage && `Storage_${params.storage}`,
    params.connectivity && `Connectivity_${params.connectivity}`,
    params.simSlot && `SimSlot_${params.simSlot}`,
    params.ram && `Ram_${params.ram}`,
    params.psku && `PSKU:${params.psku}`,
    params.screenSize && `ScreenSize_${params.screenSize}`,
    params.cpu && `Cpu_${params.cpu}`,
    params.cellularStatus && `CellularStatus_${params.cellularStatus}`,
    params.cellularNetwork && `CellularNetwork_${params.cellularNetwork}`,
    params.networkProvider && `NetworkProvider_${params.networkProvider}`,
    params.batteryHealth && `BatteryHealth_${params.batteryHealth}`,
  ].filter((t): t is string => t != null);

export const getServiceProviderStatus = (
  cellularStatus: string | undefined,
  cellularNetwork: string | undefined,
  networkProvider: string | undefined,
) => {
  const isUnlocked =
    cellularStatus === 'Unlocked' && cellularNetwork === 'CDMA';
  const isLockedProvider =
    cellularStatus === 'Locked' &&
    (networkProvider === 'Verizon' || networkProvider === 'Sprint');
  const isPartialLockedProvider =
    (cellularStatus === 'Unlocked' && cellularNetwork === 'GSM') ||
    (cellularStatus === 'Locked' &&
      (networkProvider === 'AT&T' || networkProvider === 'T-Mobile'));

  return { isUnlocked, isLockedProvider, isPartialLockedProvider };
};

export const extractDealTypes = (tags: string[]) =>
  tags
    .map((tag) => /DealType_(.*)/.exec(tag)?.[1])
    .filter((tag) => !['', 'undefined'].includes(tag || ''))
    .filter((tag): tag is string => tag != null);

export const extractDealDetails = (
  tags: string[],
): {
  ranking: number | null;
  dealEndDate: string | null;
  dealStockLimit: number | null;
} => {
  const extract = tagExtractor(tags);
  const ranking = extract(/DealRanking_(.*)/);
  const dealEndDate = extract(/DealEndDate_(.*)/);
  const dealStockLimit = extract(/DealStockLimit_(.*)/);

  return {
    ranking: ranking ? Number(ranking) : null,
    dealEndDate: dealEndDate || null,
    dealStockLimit: dealStockLimit ? Number(dealStockLimit) : null,
  };
};
