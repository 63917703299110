/** THis file has been moved away from use-auth.ts to avoid importing unused js in _app */
import { createContext } from 'react';
import cookie from 'js-cookie';

export const TOKEN_COOKIE = 'auth-token';
export const EXPIRE_COOKIE = 'auth-expire'; // we can't get with Javascript the expiration time of the cookie. So we store it.
type Token = {
  token: string;
  expireAt: string;
} | null;

export const getSavedToken = (defaultAccessToken?: string): Token => {
  if (typeof window === 'undefined' && defaultAccessToken)
    return { token: defaultAccessToken, expireAt: new Date().toISOString() };
  const token = cookie.get(TOKEN_COOKIE);
  const expireAt = cookie.get(EXPIRE_COOKIE);

  if (token == null || expireAt == null) return null;

  return { token, expireAt };
};
export const AuthContext = createContext<Token>(null);

export const setAccessToken = async (accessToken: Token) => {
  console.log('setAccessToken', accessToken);
  if (typeof window === 'undefined') return;

  if (accessToken == null) {
    cookie.remove(TOKEN_COOKIE, { sameSite: 'lax' });
    cookie.remove(EXPIRE_COOKIE, { sameSite: 'lax' });
  } else if (accessToken != null) {
    cookie.set(TOKEN_COOKIE, accessToken.token, {
      expires: new Date(accessToken.expireAt),
      sameSite: 'lax', // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite#lax
    });
    cookie.set(EXPIRE_COOKIE, accessToken.expireAt, { sameSite: 'lax' });
  }
};
