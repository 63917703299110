import type SentryT from '@sentry/node';

const Sentry: typeof SentryT =
  typeof window === 'undefined'
    ? require('@sentry/node')
    : require('@sentry/react');

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://d047defdca9747d79fd279c3ff7fb447@o1244222.ingest.sentry.io/6400502';

const STAGE = process.env.NEXT_PUBLIC_STAGE || process.env.STAGE;
const STORE = process.env.NEXT_PUBLIC_STORE || process.env.STORE;

Sentry.init({
  environment: STAGE,
  initialScope: {
    tags: {
      store: STORE,
      runtime: typeof window === 'undefined' ? 'server' : 'client',
    },
  },
  enabled: STAGE !== 'dev',
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.1,
  beforeSend: (event, hint) => {
    const error = hint?.originalException;

    if (error == null) return event;
    const message = typeof error === 'string' ? error : error.message;

    // filter out tolerated errors
    if (message?.match(/Network Error/i)) return null; // https://sentry.io/organizations/reebelo-t2/issues/3313568206/?project=6400502&query=is%3Aunresolved+environment%3Aprod
    if (message?.match(/to be interpolated properly/i)) return null; // https://sentry.io/organizations/reebelo-t2/issues/3330005458/?project=6400502&query=is%3Aunresolved+environment%3Aprod
    if (message?.match(/Failed to execute 'measure'/i)) return null; // https://sentry.io/organizations/reebelo-t2/issues/3285578932/?project=6400502&query=is%3Aunresolved+environment%3Aprod&sort=freq&statsPeriod=14d

    return event;
  },
});

export const { captureException, captureMessage, withScope, Severity } = Sentry;
export const { flush } = Sentry;
