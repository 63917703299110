import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { Logger } from './logging/logger';

export default function logAxios(
  axiosInstance: AxiosInstance,
  logger: Logger,
  format?: {
    request?: <T extends AxiosRequestConfig>(req: any) => T;
    response?: <T extends AxiosResponse<any>>(err: any) => T & { log?: string };
  },
) {
  const formatReq = format?.request || ((req: any) => req);
  const formatRes = format?.response || ((err: any) => err);

  axiosInstance.interceptors.request.use((req) => {
    const { url, params, data } = formatReq(req);
    const method = req.method?.toUpperCase();
    const args = [params, data].filter(Boolean);
    const msg = [
      `📞 Called %s %s with `,
      params && `params: %j`,
      data && `payload: %j`,
    ]
      .filter(Boolean)
      .join('\t');

    logger.info(msg, method, url, ...args);

    return req;
  });

  axiosInstance.interceptors.response.use(
    (res) => {
      const response = formatRes(res);
      const method = response.config.method?.toUpperCase();

      logger.info(
        `📝 Returned %s %s${response?.log || ''}`,
        method,
        response.config.url,
      );

      return res;
    },
    (res) => {
      const status = res.response?.status;
      const msg = `❌ Error %d from %s %s`;

      logger.info(msg, status, res?.config?.method, res.config?.url);
      logger.info(`❌ data: %j`, res.response?.data);

      return Promise.reject(res);
    },
  );
}
