import {
  FeatureFlagBooleanProperty,
  FeatureFlagNumberProperty,
  FeatureFlagStringProperty,
} from '@braze/web-sdk';
import { fetchShopifyCustomerInfo } from '@/lib/shopify';
import settings from '@/settings';
// WebpackExports comment is used to take advantage of tree shaking
// https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#alternative-integration-methods

const isSSR = typeof window === 'undefined';
const isProd =
  process.env.NEXT_PUBLIC_STAGE === 'prod' || process.env.STAGE === 'prod';

export async function initializeBraze() {
  if (isSSR) return;

  const {
    initialize,
    subscribeToContentCardsUpdates,
    automaticallyShowInAppMessages,
  } = await import(
    /* webpackExports: ["initialize", "automaticallyShowInAppMessages", 
    "subscribeToContentCardsUpdates"] */
    '@braze/web-sdk'
  );

  const apiKey = isProd ? settings.braze.api_key : settings.braze.dev_key;

  // Must be called before other methods can be invoked.
  // Subsequent calls ignored (https://js.appboycdn.com/web-sdk/4.8/doc/modules/braze.html#initialize)
  initialize(apiKey, {
    baseUrl: settings.braze.endpoint,
    enableLogging: false,
    manageServiceWorkerExternally: true,
    serviceWorkerLocation: './public/sw.js',
    sessionTimeoutInSeconds: isProd ? 1800 : 300,
    allowUserSuppliedJavascript: true, // Do not add this property to checkout.liquid SDK initialization
  });

  automaticallyShowInAppMessages();
  subscribeToContentCardsUpdates(() => {
    if (!isProd) console.log('Braze Content Cards Updated');
  });
}

export async function openBrazeSession() {
  if (isSSR) return;

  const { openSession } = await import(
    /* webpackExports: ["openSession"] */
    '@braze/web-sdk'
  );

  await initializeBraze();

  openSession();
}

export async function getAllBrazeFeatureFlags() {
  const { getAllFeatureFlags } = await import(
    /* webpackExports: ["getAllFeatureFlags"] */
    '@braze/web-sdk'
  );

  await initializeBraze();
  const result = getAllFeatureFlags();

  return result;
}

export type BrazeFeatureFlagT = {
  enabled: boolean;
  properties?: Partial<
    Record<
      string,
      | FeatureFlagStringProperty
      | FeatureFlagNumberProperty
      | FeatureFlagBooleanProperty
    >
  >;
};

export async function getBrazeFeatureFlag(
  feature_flag_id: string,
): Promise<BrazeFeatureFlagT> {
  if (isSSR) return Promise.resolve({ enabled: false });

  const { getFeatureFlag } = await import(
    /* webpackExports: ["getFeatureFlag"] */
    '@braze/web-sdk'
  );

  await initializeBraze();
  const result = getFeatureFlag(feature_flag_id);
  const { enabled, properties } = result;

  return { enabled, properties };
}

export async function changeBrazeUser(user_id: string) {
  if (isSSR) return;

  const { changeUser } = await import(
    /* webpackExports: ["changeUser"] */
    '@braze/web-sdk'
  );

  await initializeBraze();
  changeUser(user_id);
  // Call openSession after changeUser to avoid second session start
  // (https://js.appboycdn.com/web-sdk/4.8/doc/modules/braze.html#opensession)
  await openBrazeSession();
}

export async function getBrazeUserId(): Promise<string | null> {
  if (isSSR) return null;

  const { getUser } = await import(
    /* webpackExports: ["getUser"] */
    '@braze/web-sdk'
  );

  await initializeBraze();
  let brazeUserId = null;

  getUser()?.getUserId((userId) => {
    brazeUserId = userId;
  });

  return brazeUserId;
}

export async function addBrazeAlias(alias: string, label: string) {
  if (isSSR) return;

  const { getUser } = await import(
    /* webpackExports: ["getUser"] */
    '@braze/web-sdk'
  );

  await initializeBraze();
  getUser()?.addAlias(alias, label);
}

export async function setBrazeEmail(email: string) {
  if (isSSR) return;

  const { getUser, requestImmediateDataFlush } = await import(
    /* webpackExports: ["getUser", "requestImmediateDataFlush"] */
    '@braze/web-sdk'
  );

  await initializeBraze();

  getUser()?.setEmail(email);
  requestImmediateDataFlush();
}

export async function setBrazePhoneNumber(phoneNumber: string) {
  if (isSSR) return;

  const { getUser } = await import(
    /* webpackExports: ["getUser"] */
    '@braze/web-sdk'
  );

  await initializeBraze();

  getUser()?.setPhoneNumber(phoneNumber);
}

export async function logBrazeEvent(
  eventName: string,
  eventProperties?: Record<string, any>,
) {
  if (isSSR) return;

  const { logCustomEvent } = await import(
    /* webpackExports: ["logCustomEvent"] */
    '@braze/web-sdk'
  );

  await initializeBraze();
  logCustomEvent(eventName, eventProperties);

  if (!isProd) {
    const brazeUser = await getBrazeUserId();

    console.log('Braze Event Logged', {
      eventName,
      eventProperties,
      user: brazeUser ?? 'No Braze User ID associated with event',
    });
  }
}

export async function getUserAndLogBrazeEvent({
  eventName,
  payload,
  token,
  mixpanelDistinctId,
}: {
  payload?: Record<string, any>;
  token?: string;
  mixpanelDistinctId?: string;
  eventName: string;
}) {
  if (token) {
    const { customerId, customerEmail } = await fetchShopifyCustomerInfo(token);

    logBrazeEvent(eventName, {
      user_id: customerEmail ?? customerId,
      ...payload,
      store: process.env.STORE,
    });

    return;
  }

  if (mixpanelDistinctId) {
    logBrazeEvent(eventName, {
      mixpanelDistinctId,
      ...payload,
      store: process.env.STORE,
    });

    return;
  }

  logBrazeEvent(eventName, { ...payload, store: process.env.STORE });
}

export async function getBrazeContentCards(
  filterFunction: (event: any) => void,
) {
  if (isSSR) return;

  const { subscribeToContentCardsUpdates, requestContentCardsRefresh } =
    await import(
      /* webpackExports: ["subscribeToContentCardsUpdates", "requestContentCardsRefresh"] */
      '@braze/web-sdk'
    );

  await initializeBraze();

  subscribeToContentCardsUpdates(filterFunction);
  requestContentCardsRefresh();
}

export async function getSubscribeFF(featureFlagId: string) {
  const subscribedFeatureFlag = await getBrazeFeatureFlag(featureFlagId);
  const { enabled, properties } = subscribedFeatureFlag;

  return { enabled, properties };
}
