/* eslint-disable class-methods-use-this */
import { ILogger } from './ILogger';

export class ClientLogger implements ILogger {
  childTraceId: string;

  constructor(childTraceId: string) {
    this.childTraceId = childTraceId;
  }

  extend(childTraceId: string) {
    return this;
  }

  info(formatter: any, ...args: any[]) {
    console.info(formatter, ...args);
  }

  error(formatter: any, ...args: any[]) {
    console.error(formatter, ...args);
  }

  warn(formatter: any, ...args: any[]) {
    console.warn(formatter, ...args);
  }

  debug(formatter: any, ...args: any[]) {
    console.debug(formatter, ...args);
  }
}
